import React, { useRef, useState, useEffect } from "react"
import { enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"

import { createSubmit } from "./utils"
import CloseIcon from "./assets/close.inline.svg"
import SuccessIcon from "./assets/success.inline.svg"
import * as S from "./styled"

const fieldNames = ["email"]

const EnrollBetaModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(!email)
  const [step, setStep] = useState(0)
  const [error, setError] = useState(null)
  const el = useRef(null)
  const submit = createSubmit({
    portalId: "7627878",
    formId: "0a099e21-b886-4a39-a7a7-651981ad5e10",
  })

  const handleSubmit = async () => {
    setIsLoading(true)

    try {
      const result = await submit(fieldNames, { email })

      if (result.status === 200) {
        setStep(1)
      }
    } catch (err) {
      if (err.response && err.response.data) {
        err.response.data.errors.forEach(({ errorType }) => {
          if (errorType === "INVALID_EMAIL") {
            setError("Invalid email")
          }
        })
      }
    }

    setIsLoading(false)
  }

  const handleChange = e => {
    const { value } = e.target

    setEmail(value)
    setIsDisabled(!value)
    setError(null)
  }

  useEffect(() => {
    if (!el.current) {
      return
    }

    const { current } = el

    const handler = () => {
      setIsOpen(true)
    }

    current.addEventListener("classChange", handler)

    return () => {
      current.removeEventListener("classChange", handler)
    }
  }, [setIsOpen])

  useEffect(() => {
    const headerEl = document.getElementById("header")

    if (isOpen) {
      document.body.style.paddingRight = `20px`
      document.body.style.overflow = "hidden"
      headerEl.style.width = "calc(100% - 20px)"
      disableBodyScroll(el.current || document.body)
    } else {
      document.body.style.paddingRight = ""
      document.body.style.overflow = ""
      document.body.classList.remove("modal-opened")
      headerEl.style.width = "100%"
      enableBodyScroll(el.current || document.body)
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isOpen])

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <S.Container isOpen={isOpen} ref={el} id="enroll-modal" onClick={handleClose}>
      {step === 0 ? (
        <S.Modal onClick={e => e.stopPropagation()}>
          <S.CloseBtn onClick={handleClose}>
            <CloseIcon fill="var(--search-icon)" />
          </S.CloseBtn>
          <S.Content>
            <S.Headline>Enroll in beta</S.Headline>
            <S.Description>
              Request invite to our private Beta program for developers to join the waitlist. No spam, we
              promise.
            </S.Description>
            <S.FieldGroup isInvalid={!!error}>
              <label htmlFor="email">Work email:</label>
              <input type="email" name="email" id="email" value={email} onChange={handleChange} />
              <p>{error}</p>
            </S.FieldGroup>
          </S.Content>
          <S.Button disabled={isDisabled || isLoading} onClick={handleSubmit}>
            Request invite
          </S.Button>
        </S.Modal>
      ) : (
        <S.Modal onClick={e => e.stopPropagation()} isSuccess>
          <S.Content>
            <SuccessIcon />
            <S.Headline>Thank you!</S.Headline>
            <S.Description>You successfully joined the waitlist for our private Beta.</S.Description>
          </S.Content>
          <S.Button isSuccess onClick={handleClose}>
            OK
          </S.Button>
        </S.Modal>
      )}
    </S.Container>
  )
}

export default EnrollBetaModal
