import { useEffect } from "react"
import { getHeaderHeight } from "../const"

const useStartAnchor = location => {
  useEffect(() => {
    const { hash } = location

    if (hash) {
      const el = document.getElementById(hash.slice(1))

      if (!el) {
        return
      }

      const { marginTop } = window.getComputedStyle(el)
      const normalizedMarginTop = +marginTop.replace(/[A-z]/gi, "")

      window.scroll({
        top: el.offsetTop - normalizedMarginTop + getHeaderHeight() - 10,
        behavior: "smooth",
        left: 0,
      })
    }
  }, [location])
}

export default useStartAnchor
