import styled, { keyframes } from "styled-components"

import { FONT } from "const"

const flip = keyframes`
  0% {
    transform: translateY(15%);
  }
  100% {
    transform: translateY(0);
  }
`

const show = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Container = styled.div`
  display: ${props => (props.isOpen ? "flex" : "none")};
  width: 100vw;
  height: 100vh;
  padding: 16px 0;
  overflow: hidden;

  z-index: 9999;
  background-color: var(--modal-overlay);

  position: fixed;
  top: 0;
  left: 0;

  justify-content: center;
  align-items: center;
  animation: ${show} 0.5s;

  @media (max-width: 414px) {
    padding: 0;
  }
`

export const Modal = styled.div`
  width: 560px;
  min-height: ${props => (props.isSuccess ? 384 : 364)}px;

  background-color: var(--modal-bg);
  border-radius: 18px;
  animation: ${flip} 0.3s;

  position: relative;

  @media (max-width: 414px) {
    width: 100vw;
    top: 0;
    position: fixed;
    height: 100%;
    border-radius: 0;
    padding: 0;
  }
`

export const Content = styled.div`
  padding: 24px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;

  @media (max-width: 414px) {
    align-items: center;
    text-align: left;
  }

  svg {
    margin-top: 8px;
    margin-bottom: 40px;
  }
`

export const CloseBtn = styled.button`
  display: block;
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`

export const Headline = styled.h2`
  font-family: ${FONT.PRIMARY};
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: var(--title-color);
  margin: 0;
  margin-bottom: 12px;

  @media (max-width: 414px) {
    font-size: 24px;
    line-height: 32px;
    margin-top: 4px;
  }
`

export const Description = styled.p`
  font-family: ${FONT.PRIMARY};
  font-size: 15px;
  line-height: 28px;
  color: var(--text-color);
  margin: 0;
  margin-bottom: 28px;
  text-align: center;
`

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-family: ${FONT.PRIMARY};
    font-size: 15px;
    line-height: 28px;
    color: var(--input-label-color);
    min-height: 28px;
    margin-right: auto;
  }

  input {
    height: 56px;
    outline: none;
    font-family: Open Sans;
    font-size: 17px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: var(--input-color);
    background: var(--input-bg);
    border-radius: 8px;
    padding: 12px;
    border: 1px solid ${props => (props.isInvalid ? "#E63544" : "rgba(0, 0, 0, 0)")};
    margin-bottom: 4px;

    &::placeholder {
      color: var(--input-placeholder-color);
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid ${props => (props.isInvalid ? "#E63544" : "rgba(0, 0, 0, 0)")};
    -webkit-text-fill-color: var(--input-color);
    -webkit-box-shadow: 0 0 0px 1000px var(--input-bg) inset;
    transition: background-color 5000s ease-in-out 0s;
    font-size: 17px;
    line-height: 32px;
    letter-spacing: 0.2px;
  }

  p {
    font-family: ${FONT.PRIMARY};
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: var(--text-color);
    margin: 0;
  }

  @media (max-width: 414px) {
    label {
      font-size: 13px;
      line-height: 24px;
    }

    input {
      border-radius: 6px;
      height: 40px;
      font-size: 15px;
      line-height: 28px;
      padding: 8px;
    }
  }
`

export const Button = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 0 0 18px 18px;
  position: absolute;
  bottom: 0;
  border: none;
  outline: none;
  background-color: ${props => (props.isSuccess ? "#02CCCB" : "#5855f4")};
  font-family: ${FONT.SECONDARY};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: ${props => (props.isSuccess ? "rgb(2, 204, 203)" : "rgb(111, 108, 250)")};
  }

  &:active {
    background-color: ${props => (props.isSuccess ? "rgb(2, 204, 203)" : "rgb(75, 72, 242)")};
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.5);
  }

  @media (max-width: 414px) {
    margin: 16px;
    width: calc(100% - 32px);
    border-radius: 12px;
    height: 48px;
  }
`
