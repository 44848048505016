import { useState, useEffect } from "react"

const useNavigation = menu => {
  const [nextArticle, setNextArticle] = useState(null)
  const [prevArticle, setPrevArticle] = useState(null)

  useEffect(() => {
    let activeArticleIndex = -1

    let allItems = []

    if (menu.article.categories.length > 0 || menu.article?.parent?.categories.length > 0) {
      if (menu.article.parent.categories.length > 0) {
        allItems = [{ ...menu.article.parent, title: "Overview" }]
        menu.article.parent.categories.forEach(category => {
          allItems = [...allItems, ...category.items]
        })
      }

      if (menu.article.categories.length > 0) {
        allItems = [{ ...menu.article, title: "Overview" }]
        menu.article.categories.forEach(category => {
          allItems = [...allItems, ...category.items]
        })
      }

      let index = null

      for (let i = 0; i < allItems.length; i++) {
        if (allItems[i].id === menu.article.id) {
          index = i
        }
      }

      setNextArticle(allItems[index + 1] ? allItems[index + 1] : null)
      setPrevArticle(allItems[index - 1] ? allItems[index - 1] : null)
    } else {
      for (let workspace of menu.menu) {
        for (let category of workspace.categories) {
          allItems = [...allItems, ...category.items]
        }
      }

      for (let i = 0; i < allItems.length; i++) {
        if (allItems[i].id === menu.article.id) {
          activeArticleIndex = i
        }
      }

      if (activeArticleIndex !== -1) {
        setNextArticle(!!allItems[activeArticleIndex + 1] ? allItems[activeArticleIndex + 1] : null)
        setPrevArticle(!!allItems[activeArticleIndex - 1] ? allItems[activeArticleIndex - 1] : null)
      }
    }
  }, [menu])

  return { nextArticle, prevArticle }
}

export default useNavigation
