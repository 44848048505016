import React, { useEffect, useState } from "react"
import { createPortal } from "react-dom"

import Link from "components/Link"
import { getHeaderHeight } from "const"
import * as S from "./styled"

const PageNavigation = ({ dotsOnly, dots, hash, items = [] }) => {
  const [activeHash, setActive] = useState(hash)
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    setActive(hash)
  }, [hash, setActive])

  useEffect(() => {
    const onscroll = () => {
      let currentItem = null
      for (let item of items) {
        const el = document.getElementById(item.url.slice(1))
        if (window.scrollY + getHeaderHeight() > el.offsetTop) {
          currentItem = item
        }
      }
      setActive(currentItem?.url)
    }

    window.addEventListener("scroll", onscroll)

    return () => window.removeEventListener("scroll", onscroll)
  }, [items, setActive])

  const tryOpenModal = () => dotsOnly && setOpen(true)

  return (
    <>
      <S.List onClick={tryOpenModal} onMouseEnter={tryOpenModal}>
        {items.map(({ url, title }) => (
          <S.Item isDot={dots} key={url} isActive={url === activeHash}>
            {!dotsOnly && <Link href={url}>{title}</Link>}
          </S.Item>
        ))}
      </S.List>

      {typeof document !== `undefined` &&
        createPortal(
          <div>
            {isOpen && <S.Overlay onClick={() => setOpen(false)} />}
            <S.ModalList isOpen={isOpen} onMouseLeave={() => setOpen(false)}>
              {items.map(({ url, title }) => (
                <S.Item onClick={() => setOpen(false)} isDot={dots} key={url} isActive={url === activeHash}>
                  <Link href={url}>{title}</Link>
                </S.Item>
              ))}
            </S.ModalList>
          </div>,
          document.body
        )}
    </>
  )
}

export default PageNavigation
