import styled from "styled-components"

export const Content = styled.div`
  max-height: 100%;
  display: flex;
  position: relative;

  width: 100%;
  display: flex;
  justify-content: center;
`

export const Main = styled.main`
  width: 760px;
  @media (max-width: 1374px) {
    width: 74%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    padding-right: 12px;
  }
`

export const Navigation = styled.div`
  position: relative;
  margin-left: 40px;
  height: calc(100% + 24px);
  width: 214px;

  @media (max-width: 1374px) {
    width: calc(26% - 40px);
  }

  > ul {
    width: 100%;
    position: sticky;
    top: calc(24px + var(--header-size));
    height: calc(100vh - var(--header-size) - 24px);
  }

  @media (max-width: 800px) {
    height: initial;
    min-width: 8px;
    max-width: 8px;
    position: fixed;
    top: calc(24px + var(--header-size));
    right: 12px;

    > ul {
      min-width: 8px;
      max-width: 8px;
      height: initial;
    }
  }
`
