import React from "react"
import { Fade } from "react-awesome-reveal"

import Common from "components/Common"
import TitleBox from "components/TitleBox"
import Markdown from "components/Markdown"
import FoundBug from "components/FoundBug"
import PageNavigation from "components/PageNavigation"
import EnrollBetaModal from "components/EnrollBetaModal"
import ArticlesNavigation from "components/ArticlesNavigation"

import useFindText from "hooks/useFindText"
import useStartAnchor from "hooks/useStartAnchor"
import useBreakpoint from "hooks/useBreakpoint"

import * as S from "./styled"

const Layout = ({ location, title, date, content, menu, navigation, meta }) => {
  const findTextProps = useFindText(location)
  const [isTablet, isMobile] = useBreakpoint([1200, 800])
  useStartAnchor(location)

  return (
    <Common meta={meta} menu={menu} {...findTextProps}>
      <S.Content>
        <S.Main>
          <Fade duration={200}>
            <TitleBox menu={menu} title={title} date={date} />
            <Markdown content={content} {...findTextProps} />
            <FoundBug />
            <ArticlesNavigation menu={menu} />
          </Fade>
        </S.Main>
        <S.Navigation>
          <PageNavigation
            dots={isTablet}
            dotsOnly={isMobile}
            hash={location?.hash}
            items={navigation?.items}
          />
        </S.Navigation>
      </S.Content>
      <EnrollBetaModal />
    </Common>
  )
}

export default Layout
