import styled, { css } from "styled-components"
import { Link } from "gatsby"

import { FONT } from "const"

export const Container = styled.div`
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
`

const linkStyle = css`
  display: flex;
  font-family: ${FONT};
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  color: var(--article-navigation);
  text-decoration: none;
  word-break: break-word;
  width: 45%;

  svg {
    path {
      fill: var(--article-navigation);
    }
  }

  &:hover {
    color: var(--article-navigation-hover);

    svg {
      path {
        fill: var(--article-navigation-hover);
      }
    }
  }
`

export const LeftLink = styled(Link)`
  ${linkStyle}
  margin-right: auto;
  text-align: left;

  svg {
    margin-right: 8px;
    margin-top: 3px;
  }
`

export const RightLink = styled(Link)`
  ${linkStyle}
  margin-left: auto;
  justify-content: flex-end;
  text-align: right;

  svg {
    margin-left: 8px;
    margin-top: 3px;
  }
`
