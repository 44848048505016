import styled, { css } from "styled-components"

import { FONT } from "const"

export const List = styled.ul`
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;

  li + li {
    margin-top: 10px;
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const ModalList = styled.ul`
  top: calc(12px + var(--header-size));
  box-shadow: 0px 1px 4px var(--navigation-shadow);
  background: var(--navigation-bg);

  position: fixed;
  right: 12px;
  margin: 0;
  padding: 12px;

  max-width: 172px;
  overflow-y: auto;
  border-radius: 4px;
  max-height: calc(90vh - 75px - 24px);

  transition: 0.2s transform;
  transform: translateX(${p => (p.isOpen ? 0 : 200)}px);

  li + li {
    margin-top: 10px;
  }
`

const dot = css`
  &::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    flex-shrink: 0;
    margin-right: 8px;
    margin-top: 8px;

    ${p => p.isActive && "background: var(--navigation-item-active)"};
    ${p => !p.isActive && "border: 1px solid var(--navigation-item-dot)"};
  }
`

export const Item = styled.li`
  align-items: flex-start;
  display: flex;
  ${p => p.isDot && dot};

  a {
    font-family: ${FONT.PRIMARY};
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    text-align: left;
    text-decoration: none;
    color: var(${p => (p.isActive ? "--navigation-item-active" : "--navigation-item")});

    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;

    :hover {
      color: var(--navigation-item-hover);
    }
  }
`
