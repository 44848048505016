import React from "react"

import useNavigation from "./useNavigation"
import * as S from "./styled"
import ArrowLeft from "./assets/arrow-left.inline.svg"
import ArrowRight from "./assets/arrow-right.inline.svg"

const ArticlesNavigation = ({ menu }) => {
  const { nextArticle, prevArticle } = useNavigation(menu)

  return (
    <S.Container>
      {prevArticle && (
        <S.LeftLink to={prevArticle.path}>
          <ArrowLeft /> <span>{prevArticle.title}</span>
        </S.LeftLink>
      )}
      {nextArticle && (
        <S.RightLink to={nextArticle.path}>
          <span>{nextArticle.title}</span>
          <ArrowRight />
        </S.RightLink>
      )}
    </S.Container>
  )
}

export default ArticlesNavigation
