import axios from "axios"

export const createSubmit = ({ portalId, formId, isDetectPage = true }) => {
  return async (fieldsNames, fieldsValues) => {
    const url = window ? window.location.href : ""
    const pageName = url ? url.split("/").slice(-1)[0] || "Main" : "Main"

    const data = {
      submittedAt: +new Date(),
      fields: isDetectPage ? [{ name: "url", value: url }] : [],
      skipValidation: false,
    }

    fieldsNames.forEach(name => {
      data.fields.push({
        name,
        value: fieldsValues[name],
      })
    })

    data.context = {
      pageUri: url,
      pageName,
      pageId: pageName,
    }

    const result = await axios({
      method: "POST",
      url: `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
      data,
      headers: { "Content-Type": "application/json" },
    })

    return result
  }
}
